import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/sections',
      name: 'sections',
      component: () => import('@/views/sections/index.vue')
    },
    {
      path: '/students/view',
      name: 'students/view',
      component: () => import('@/views/students/view.vue')
    },
    {
      path: '/students/import',
      name: 'students/import',
      component: () => import('@/views/students/import.vue')
    },
    {
      path: '/students/auto-parents',
      name: 'students/auto-parents',
      component: () => import('@/views/students/auto-parents.vue')
    },
    {
      path: '/students/add-or-edit',
      name: 'students/add-or-edit',
      component: () => import('@/views/students/addAndEdit.vue')
    },
    {
      path: '/students/overview/:id',
      name: 'students/overview',
      component: () => import('@/views/students/overview.vue')
    },
    {
      path: '/groups/add-or-edit',
      name: 'groups/add-or-edit',
      component: () => import('@/views/groups/addAndEdit.vue')
    },
    {
      path: '/groups/view',
      name: 'groups/view',
      component: () => import('@/views/groups/index.vue')
    },
    {
      path: '/busses/add',
      name: 'busses/add',
      component: () => import('@/views/busses/add-and-edit.vue')
    },
    {
      path: '/admins/add',
      name: 'admins/add',
      component: () => import('@/views/admins/add-or-edit.vue')
    },
    {
      path: '/admins/view',
      name: 'admins/view',
      component: () => import('@/views/admins/view.vue')
    },
    {
      path: '/busses/view',
      name: 'busses/view',
      component: () => import('@/views/busses/view.vue')
    },
    {
      path: '/parents/add-or-edit',
      name: 'parents/add',
      component: () => import('@/views/parents/add-or-edit.vue')
    },
    {
      path: '/parents/view',
      name: 'parents/view',
      component: () => import('@/views/parents/view.vue')
    },
    {
      path: '/parents/overview/:id',
      name: 'parents/overview',
      component: () => import('@/views/parents/overview.vue')
    },
    {
      path: '/parents/devices',
      name: 'parents/devices',
      component: () => import('@/views/parents/devices.vue')
    },
    {
      path: '/messages-notifications-config',
      name: 'messages-notifications-config',
      component: () => import('@/views/web/messages-notifications-config.vue')
    },
    {
      path: '/archive',
      name: 'archive',
      component: () => import('@/views/trips/archive.vue')
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/web/notifications.vue')
    },
    {
      path: '/general-settings',
      name: 'general-settings',
      component: () => import('@/views/web/general-settings.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/web/profile.vue')
    },
    {
      path: '/alerts',
      name: 'alerts',
      component: () => import('@/views/web/alerts.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/web/news.vue')
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('@/views/web/subscribe.vue')
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('@/views/web/logs.vue')
    },
    {
      path: '/app',
      name: 'app',
      component: () => import('@/views/web/app.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/logout.vue')
    },
    {
      path: '/per',
      name: 'per',
      component: () => import('@/views/error/no-per.vue')
    },
    {
      path: '/ref/:if',
      name: 'ref',
      component: () => import('@/views/error/ref.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/no-per',
      name: 'no-per',
      component: () => import('@/views/error/no-per.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-404-r',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
