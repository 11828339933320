import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
// import VueSocketIO from 'vue-3-socket.io'
// import SocketIO from "socket.io-client"

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
var user = JSON.parse(localStorage.getItem('user'))
window.api = process.env.VUE_APP_API
window.checkPer = (per) => {
  var checked = false
  if (user) {
    if (user.all_rules == 'true' || user.all_rules === true || !user.admin_id) {
      checked = true
    } else {
      checked = user.rules.includes(per) && user.rules.includes(per.split('-')[0])
    }
  }
  return checked
}
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
import 'vue-select/dist/vue-select.css';

// Composition API
Vue.use(VueCompositionAPI)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: SocketIO(process.env.VUE_APP_API, {
//     auth: {
//       user: localStorage.getItem("user")
//     },
//     transports: ["websocket"]
//   })
// }))

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
